import React from "react";
import "./about.css";
import LV from "../../assets/louis-vuitton.webp"

const data = [
    {
        id: 1,
        image: "icon-graduation",
        title:"Diplômes",
        texts:["2024 - Titre professionnel Concepteur Designer UI, Arinfo","2023 - Certificat Design UX, Google","2019 - Ingénieure Généraliste, IMT Atlantique"]
    },
    {
        id: 2,
        image: "icon-briefcase",
        title:"Expériences",
        texts:["2024 - Développeuse Front et Créatrice de sites web freelance", "2021 - Cheffe d'équipes, Louis Vuitton","2019 - Ingénieure Qualité, Louis Vuitton"]
    },
    {
        id: 3,
        image: "icon-diamond",
        title:"Compétences",
        texts:["Design UI & UX", "HTML, CSS, JavaScript, TypeScript, React", "Wordpress", "SEO","Power Bi & Automate","Notions de Python, Java, SQL et php"]
    },
    {
        id: 4,
        image: "icon-screen-desktop",
        title:"Logiciels",
        texts:["VS Code","Figma","Adobe XD","Adobe Photoshop", "Adobe Illustrator","Adobe Premiere Pro","Adobe After Effect"]
    },
]

const About = () => {
    return ( 
        <section className="about_section" id="about">
            <h2>Créatrice de sites web en Vendée et à Nantes</h2>
            <div className="about_intro">
                    <p>Ingénieure de formation, j’ai travaillé 4 ans pour <b>Louis Vuitton</b>.
                    Tout d'abord en tant qu'<b>Ingénieure Qualité</b>, j'ai mené divers projets, notamment l'automatisation d'analyse de données avec PowerBi et PowerAutomate. </p><br/>
                    <p>J'ai ensuite été promue <b>Cheffe d'équipe</b> en production. J'ai managé 25 à 35 personnes sur 3 lignes de fabrication de maroquinerie. 
                    Je pilotais également les indicateurs de sécurité, qualité et perfomance du secteur.</p><br/>
                    <p>Au cours de cette belle expérience, j'ai développé de fortes compétences de <b>gestion de projet et de management</b> (hiérarchique et transverse).</p>
                </div> 
            <div className="about_div">
                <img src={LV} className="about_img" alt="photo d'une boutique Louis Vuitton à Paris"/>
                <div className="parag">
                    <p className="about_txt"> Louis Vuitton, c'est la rigueur, l'excellence, mais c'est aussi l'innovation, le voyage et l'importance de l'esthétique. J'ai passé 4 ans complètement plongé dans cet univers, et ça a bel et bien confirmé mon amour du design.</p>
                    <p className="about_txt">J’ai alors souhaité m’orienter vers un métier à la fois technique et créatif. J'ai obtenu les certificats <strong>Design UX</strong> de Google et le titre professionnel de Concepteur <strong>Designer UI</strong>.</p>
                    <p className="about_txt">Je n'ai tout de même pas abandonné mes compétences d'ingénieure, puisque j'ai appris à coder en <strong>HTML, CSS, JavaScript, TypeScript et React</strong>. Je me suis appuyée sur les solides bases de programmation développées en Ecole d'ingénieur, et j'ai découvert ces nouveaux langages en formation et en auto-didacte.</p>
                    <p className="about_txt">Aujourd’hui, je mets mon expertise à votre service, que vous soyez une entreprise spécialisée dans le développement ou le design UI / UX, ou un.e chef.fe d’entreprise souhaitant créer ou refaire son site.</p>
                    <p className="about_txt">Je travaille principalement aux alentours de <strong>La Roche-sur-Yon (Vendée 85) et Nantes</strong>, mais il est tout à fait possible de travailler ensemble par visio, où que vous soyez.</p>
                </div>
            </div>
            
                                  

            <div className="comp_div ">
                    {data.map(({id,image,title, texts})=> (
                        <div className="comp_card" key={id}>
                            <div className="comp_title">
                                <i className={image}></i>
                                <h3>{title}</h3>
                            </div>
                            <div className="comp_text">
                                {texts.map(text=> (
                                    <p>{text}</p>
                                ))
                                }
                            </div>
                        </div>
                    ))}
            </div>

        </section>
     );
}
 
export default About;