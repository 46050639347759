import React from "react";
import './footer.css';
import logo from "../../assets/logotype-noir.webp";
import pdf_cgv from "../../assets/CGV.pdf";
import pdf_mentions from "../../assets/Mentions_leg.pdf"

const Footer = () => {

    return (

        <footer className="footer_section">
            <div className="footer_top">

                <div className="footer_logo">
                    <img src={logo} alt="Logo de Chloé Garrigue"/>
                    <div className="footer_name">
                        <p id="footer_chloe">Chloé Garrigue</p>
                        <p>La Roche-sur-Yon</p>
                    </div>
                </div>

                <div id="footer_line"></div>

            </div> 

            <div className="footer_mid">
                <div className="coord">
                    <p><strong>Coordonnées</strong></p>

                    <span>
                        <i className="icon-screen-smartphone" />
                        <p>06.48.72.63.51</p>
                    </span>               
                    
                    <span>
                        <i className="icon-envelope" />
                        <p>chloe.garrigue.pro@gmail.com</p>
                    </span>
                    
                    <span>
                        <i className="icon-location-pin" />
                        <p>La Roche sur Yon, Vendée (85)</p>
                    </span>
                </div>

                <div className="horaires">
                    <p><strong>Horaires</strong></p>

                    <span>
                        <i className="icon-event" />
                        <p>Du lundi au vendredi</p>
                    </span>
                    
                    <span>
                        <i className="icon-clock" />
                        <p>De 9h30h à 18h30</p>
                    </span>
                    
                </div>

                    <div id="footer_socials">
                        <a href="https://www.instagram.com/chloe_arty_/" target="_blank" rel="noreferrer">
                            <i className="icon-social-instagram" />
                            <p className="social_tags">Instagram</p>
                        </a>

                        <a href="https://www.linkedin.com/in/chloé-garrigue-713804151" target="_blank" rel="noreferrer">
                            <i className="icon-social-linkedin" />
                            <p className="social_tags">Linkedin</p>
                        </a>
                        
                        
                    </div>


            </div>  
            
            <div className="footer_bottom">
                <div id="links">
                    <a href={pdf_mentions} target="_blank" rel="noreferrer">Mentions légales</a>
                    <a href={pdf_cgv} target="_blank" rel="noreferrer">CGV</a>
                </div>

                <div id="credits">
                    <p>Copyright© 2024 Chloé Garrigue - Créé par Chloé Garrigue</p>
                </div>
                
            </div>

        </footer>

      );
}
 
export default Footer ;