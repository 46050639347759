import React from "react";

const ProjectCard = (props :{img:any, title:string, tags:any, link:any}) => {

    return (  
        <div className="work_card">
            <a href={props.link} target="_blank" rel="noreferrer" className="work_thumbnail">
                <img className="work_img" alt="capture d'écran de la page d'accueil du site, créé par Chloé Garrigue" src={props.img}/>
                <span className="work_mask">
                    <span className="see_more">Voir</span>
                </span>
                <span className="work_tags">
                    {props.tags.map((tag:any)=>(
                        <div className="work_tag" key={tag.id}>
                            <div>{tag}</div>
                        </div>
                    ))}
                </span>
                <h3 className="work_title">{props.title}</h3>
            </a>
        </div>

    );
}
 
export default ProjectCard;