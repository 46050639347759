import React from "react";
import "./values.css";

const data = [
    {
        id: 1,
        title:"De l'éthique",
        texts:["Ce que je crée doit être accessible au plus grand nombre et inclusif.",
            "Je m’appuie les recommandations de la Web Accessibility Initiative."]
    },
    {
        id: 2,
        title:"De la rigueur",
        texts:["Je suis ingénieure, mon parcours m’a appris à être minutieuse.",
            "J’applique la même rigueur pour résoudre une équation différentielle ou un bug !"]
    },
    {
        id: 3,
        title:"De la clarté",
        texts:["J’adore expliquer des choses, alors je fais tout pour vous proposer :",         
            "des prestations claires, des étapes claires et des prix clairs."]
    },
    {
        id: 4,
        title:"De la confiance",
        texts:["Partant du principe précédent (la clarté), j’aspire à une grande confiance avec mes clients.",
            "Après tout, Louis Vuitton m’a fait confiance, et vous ?"]
    },
    {
        id: 5,
        title:"Des sourires",
        texts:["Je l’écris ici, mais vous allez très vite vous en rendre compte.",
            "Au moins, vous serez prévenu.e !"]
    }
]

const Values = () => {
    return (
        <>
            <section className="values_section" id="values">
                <h2>Travailler avec moi c'est ...</h2>

                <div className="val_div">
                    {data.map(({id,title,texts})=> (
                        <div className="val_card" key={id}>

                            <h3 className="val_title">{title}</h3>
                            <ul className="val_text">
                                {texts.map((text => (
                                <><li>{text}</li><br/></>
                                )))}
                            </ul>
                                
                        </div>
                            
                    ))}
                </div>
            </section>
        </>
        
     );
}
 
export default Values;