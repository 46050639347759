import React from "react";
import "./home.css";

const Home = () => {
    return ( 
        <section className="home_contaigner" id="home">
            <div className="intro">
                
                <div><p id="mon-nom">Chloé Garrigue</p></div>
                <div><h1>Développeuse, intégratrice & designer web</h1></div>

                <a href="#contact" className="btn">DEVIS GRATUIT</a>
                
            </div>

        </section>

     );
}
 
export default Home;