import React from 'react';
import './App.css';

import About from "./components/about/About.tsx";
import AgenceCom from './components/agencecom/AgenceCom';
// import Clients from "./components/clients/Clients.tsx";
import Contact from "./components/contact/Contact.tsx";
import Home from "./components/home/Home.tsx";
import Projects from "./components/projects/Projects.tsx";
import Services from "./components/services/Services.tsx";
import Header from './components/header/Header.tsx';
import Values from './components/values/Values';
import Footer from './components/footer/Footer'
import CookieManagement from './components/cookies/CookieManagement.tsx';

function App() {
  return (
    <>
    <Header />

    <main className='main'>
      <Home />
      <AgenceCom />
      <Services />
      <Values/>
      <Projects />
      {/* <Clients /> */}
      <About />
      <Contact />
      <Footer />
      <CookieManagement />
    </main>

    </>
  );
}

export default App;
