import React from "react";
import "./projects.css";
import { useState } from "react";

import Orchidee from "../../assets/design-site-web-restaurant.webp";
import Bear from "../../assets/design-site-web-musique.webp";
import Maeva from "../../assets/site-web-psychologue.webp";
import Elsa from "../../assets/site-web-animaux.webp";
import Lolo from "../../assets/site-web-plombier.webp";
import Galerie from "../../assets/galerie-web-css-javascript.webp"

import ProjectCard from "./ProjectCard";

// liste des projets
const works = [
    {
        id : 0,
        image : Orchidee,
        title : "Design site restaurant",
        tags : ["Design UI & UX"],
        link : "https://xd.adobe.com/view/43983662-c92c-46d7-b7b1-5b6ee87cce52-16cb/",
    },
    {
        id : 1,
        image : Bear,
        title : "Design site label de musique",
        tags : ["Design UI & UX"],
        link : "https://xd.adobe.com/view/90d3ca4c-8635-4638-b5eb-a7db737eaea0-c580/",
    },
    {
        id : 2,
        image : Maeva,
        title : "Site web Psychologue",
        tags : ["Design UI & UX", "Création de site"],
        link : "https://www.vialapsy.fr/",
    },
    {
        id : 3,
        image : Elsa,
        title : "Site web Comportementaliste",
        tags : ["Design UI & UX", "Création de site"],
        link : "https://elsa-comportementaliste.fr/",
    },
    {
        id : 4,
        image : Lolo,
        title : "Site web Plombier",
        tags : ["Design UI & UX", "Création de site"],
        link : "https://allololoplombier.fr/",
    },
    {
        id : 5,
        image : Galerie,
        title : "Ma galerie d'effets & animations",
        tags : ["Design UI & UX", "Développement"],
        link : "https://galerie.chloe-garrigue.fr/",
    },
]

//liste des filtres
const filters = [
    {id : 0,
        title : "Tous"
    },
    {id : 1,
        title : "Développement"
    },
    {id : 2,
        title : "Design UI & UX"
    },
    {id : 3,
        title : "Création de site"
    }
]


const Projects = () => {

    const [activeFilter, setActiveFilter] = useState("Tous");

    function FiltersProjects(activeFilter : any) {
        if (activeFilter === "Tous") {
            return works
        } else {
            const filteredProjects = works.filter((work) => (
                work.tags.includes(activeFilter)))
            return filteredProjects
        }
    }


    return ( 
        
        <section className="projects_section" id="projects">
            <h2>Mes réalisations</h2>         

            <div className="filters">
                {filters.map((filter) => (
                    <div key={filter.id}>
                        <span
                        className={activeFilter === filter.title ? "filter_tag_active" : "filter_tag"}
                        onClick={() => {setActiveFilter(filter.title)}}>

                            {filter.title}

                        </span>
                    </div>
                ))}
            </div>

            <select className="select" value={activeFilter} onChange={e => setActiveFilter(e.target.value)}>
                {filters.map((filter) => (
                        <option value={filter.title} key={filter.id}>
                            {filter.title}
                        </option>
                    ))}
            </select>
            <span className="icon-arrow-down" id="select_arrow"></span>

            

            <div className="projects_grid">
                {FiltersProjects(activeFilter).map((work)=> (
                    <ProjectCard img = {work.image} title = {work.title} tags={work.tags} link={work.link} key={work.id}/>))
                }

            </div>

        </section>

     );
}
 
export default Projects;