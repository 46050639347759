import React from "react";
import "./services.css";
import Tab from "./Tab";

const Services = () => {
    return ( 
        
        <section className="services_section" id="services">
            <h2>Que puis-je faire pour vous ?</h2>         
            <Tab />
        </section>
     );
}
 
export default Services;