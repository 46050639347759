import React, { useRef } from "react";
import "./contact.css";
import emailjs from 'emailjs-com';
import moi from "../../assets/chloe-garrigue-web-front.webp"

const Contact = () => {

    const refForm = useRef<HTMLFormElement>(null);

    const sendEmail = (e : React.FormEvent<HTMLFormElement>) => {

        e.preventDefault()

        if(refForm.current){
            emailjs
            .sendForm('service_jr6s12e', 'template_nuo0iyh', refForm.current, '5GZfUTBYqUtPW2cFc')
            .then(
                () => {
                alert('Votre message a bien été envoyé.');
                window.location.reload();
                },
                (error) => {
                alert("Votre message n'a pas pu s'envoyer, veuillez réessayer");
                },
            );
        }
        
    }


    return ( 
        
        <section className="contact_section" id="contact">
            <h2>Alors, on travaille ensemble ?</h2>

            <div className="contact_container">

                <div className="contact_info">
                    <h3>Contactez-moi pour obtenir votre devis gratuit.</h3>
                    <span className="contact_info_span">
                        <i className="icon-screen-smartphone" />
                        <p>06.48.72.63.51</p>
                    </span>               
                    
                    <span className="contact_info_span">
                        <i className="icon-envelope" />
                        <p>chloe.garrigue.pro@gmail.com</p>
                    </span>
                    
                    <span className="contact_info_span">
                        <i className="icon-location-pin" />
                        <p>La Roche sur Yon, Vendée</p>
                    </span>
                    
                    
                    <img src={moi} className="contact_img" alt="portrait de Chloé Garrigue à son bureau"/>
                </div>

                <form action="" className="contact_form" ref={refForm} onSubmit={sendEmail}>
                    <div className="contact_form_group">
                        <div className="contact_form_div">
                            <label>Nom</label>
                            <input name="nom" type="text" className="contact_form_input" placeholder="Saisir votre nom" required/>
                        </div>

                        <div className="contact_form_div">
                            <label>Prénom</label>
                            <input name="prenom" type="text" className="contact_form_input" placeholder="Saisir votre prénom" required/>
                        </div>

                        <div className="contact_form_div">
                            <label>Adresse mail</label>
                            <input name="mail" type="email" className="contact_form_input" placeholder="Saisir votre adresse mail" required/>
                        </div>

                        <div className="contact_form_div">
                            <label>Téléphone</label>
                            <input name="tel" type="text" className="contact_form_input" placeholder="Saisir votre numéro" required/>
                        </div>
                    </div>

                    <div className="contact_form_div">
                        <label>Votre message</label>
                        <textarea name="message" id="" cols={20} rows={20} className="contact_form_input" placeholder="Saisir votre message" required></textarea>
                    </div>

                    <div className="contact_form_rgpd">
                    <label>J'accepte de partager mes données avec Chloé Garrigue, afin qu'elle puisse me recontacter.</label>
                        <input type="checkbox" required />
                    </div>

                    <button className="btn" type="submit">Envoyer</button>

                </form>
            </div>

        </section>
     );
}
 
export default Contact ;