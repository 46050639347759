import React from "react";
import { CookieConsent } from "react-cookie-consent";
import './cookiemng.css'

const CookieManagement = () => {
    return (  
        <CookieConsent
            location="bottom"
            disableStyles={true}
            buttonText="J'accepte"
            enableDeclineButton
            declineButtonText="Je refuse"
            cookieName="CookieConsent"
            expires={150}
            containerClasses="cookie_container"
            buttonClasses="cookie_button"
            declineButtonClasses="cookie_decline"
            contentClasses="cookie_content"
            >
                Ce site utilise des cookies.
            </CookieConsent>
    );
}
 
export default CookieManagement;