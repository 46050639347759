import React, { useRef } from "react";
import "./agence-com.css";

const AgenceCom = () => {

    return (
        <section className="agencecom_section" id="agencecom">

            <h2>Vous êtes une agence ?</h2>

            <p>Agence de communication, agence web ou encore agence de développement ... </p>
            <p>Si vous cherchez un.e professionnel.le avec des compétences techniques qui pourra épauler votre agence : Vous êtes au bon endroit !</p>
            
            <div className="div-solutions">
                
                <div className="solutions">
                    <i className="icon-fire"/>
                    <h3>Effets et animations qui font toute la différence</h3>
                    <p>Animation du menu burger, header transparent qui devient opaque, animation au scroll, ... </p>
                    <a href="https://galerie.chloe-garrigue.fr/" target="_blank" rel="noreferrer">Découvrez un échantillon de mon savoir-faire dans la galerie des effets et animations !</a>
                </div>

                <div className="solutions">
                    <i className="icon-magic-wand"/>
                    <h3>Enfin des solutions aux contraintes des CMS !</h3>
                    <p>Votre client veut changer un mot ou des paramètres sur son site, mais Wordpress ne vous le permet pas ?</p>
                    <p>Je débloque la situation rapidement pour satisfaire votre client !</p>
                </div>

                <div className="solutions">
                    <i className="icon-graph"/>
                    <h3>Besoin d'aide lors d'un pic de charge ?</h3>
                    <p>Vos clients viennent vous voir avec des super projets, mais vous n'avez plus la capa en interne ?</p>
                    <p>Je renforce vos équipes pour vous permettre de maintenir vos délais auprès de vos clients.</p>
                </div>

                <div className="solutions">
                    <i className="icon-people"/>
                    <h3>Un.e de vos employés préférés s'absente ...</h3>
                    <p>Je sais, c'est dur. Et loin de moi l'idée de remplacer un de vos chers collaborateurs !</p>
                    <p>Je peux néanmoins prendre une partie ou la totalité de sa charge de travail en son absence.</p>
                </div>

            </div>

        </section>
        

    )

}

export default AgenceCom ;