import React from "react";
import "./services.css";
import { useState } from "react";
import Dev from "../../assets/developpement-web.webp";
import Design from "../../assets/design-web-ui-ux.webp";
import Site from "../../assets/site-wordpress.webp";
import Maintenance from "../../assets/maintenance-site-wordpress.webp";

const data = [
    {id : 0,
    name : "Développement",
    descr : "Votre nouveau réflexe : j'ai un problème technique, j'appelle Chloé !",
    icon : "icon-screen-desktop",
    photo : Dev,
    texts : ["Développement front en HTML, CSS, JavaScript & TypeScript", 
        "Développement front sous React", 
        "Compétences techniques diverses : VBA (macro Excel), Power Bi & Automate, Python, Java, SQL, PHP",
        "Ajout d'effets et animations sur des sites existants",
        "Résolution de problèmes liés aux contraintes des CMS",
        "Résolution de problème technique divers"],
    },
    {id : 1,
    name : "Création de sites Wordpress",
    descr : "Je crée et mets en ligne votre site. Je m'occupe de tout, même de l'hébergement !",
    icon : "icon-globe",
    photo:Site,
    texts : ["Définition du besoin",
        "Étude concurrentielle",
        "Création d’une maquette du site",
        "Mise en place de l’hébergement",
        "Mise en ligne du site responsive",
        "SEO (Optimisation du référencement)"],
    },
    {id : 2,
        name : "Design UI & UX",
        descr : "Je design votre site, application ou interface, avec rigueur et style !",
        icon : "icon-vector",
        photo:Design,
        texts : ["Recherche UX",
            "Parcours utilisateur",
            "Étude concurrentielle",
            "Accessibilité",
            "Wireframes et maquettes",
            "Etude d’utilisabilité"],
        },
    {id : 3,
    name : "Maintenance de site web",
    descr: "Je prend soin de votre site pour qu'il reste sécurisé et performant !",
    icon : "icon-wrench",
    photo:Maintenance,
    texts : ["Sauvegarde du site",
        "Mise à jour des extensions",
        "Analyse et optimisation du site",
        "Vérification du bon fonctionnement",
        "Hébergement",
        "Nom de domaine"],
    },
]

const Tab = () => {

    const [active, setActive] = useState(0);

    return (
        <>
        <div className="div_tab">
                <span onClick={()=>setActive(0)} className={data[0].id === active ? "presta_active" : "presta"}>Développement</span>
                <span onClick={()=>setActive(1)} className={data[1].id === active ? "presta_active" : "presta"}>Création de site Wordpress</span>
                <span onClick={()=>setActive(2)} className={data[2].id === active ? "presta_active" : "presta"}>Design UI & UX</span>
                <span onClick={()=>setActive(3)} className={data[3].id === active ? "presta_active" : "presta"}>Maintenance de site web</span>
        </div>

        <select className="select_tab" value={active} onChange={e => setActive(parseInt(e.target.value))}>
                <option value={0}>Développement</option>
                <option value={1}>Création de site Wordpress</option>
                <option value={2}>Design UI & UX</option>
                <option value={3}>Maintenance de site web</option>
        </select>
        <span className="icon-arrow-down" id="select_arrow"></span>


        <div className="div_presta">
            <div className="intro_presta">
                <span>
                    <img src={data[active].photo} alt={data[active].name}/>
                </span>
                <a href="#contact" className="btn_presta">DEVIS GRATUIT</a>
            </div>
            <div className="descr_presta">
                <h3 className="name_presta">{data[active].name} <i className={data[active].icon}/></h3>
                <p>{data[active].descr}</p><br/>
                <p>La prestation comprend les élements suivants :<br/>
                <ul>
                {data[active].texts.map((text, index) => (
                    <li key={index}>{text}</li>
                ))}
                </ul>
                </p>
            </div>
        </div>
        </>
     );
}
 
export default Tab;