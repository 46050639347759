import React, { useState } from "react";
import "./header.css";
import Logo from "../../assets/logotype-noir.webp";

const menu = [
    {
        title : "PRESTATIONS",
        link : "#services",
        id : "",
        key : "0"
    },
    {
        title : "RÉALISATIONS",
        link : "#projects",
        id : "",
        key:"1"
    },
    {
        title : "À PROPOS",
        link : "#about",
        id : "",
        key:"2"
    },
    {
        title : "CONTACT",
        link : "#contact",
        id : "contact_btn",
        key:"3"
    }
]

const Header = () => {

    const [open, setOpen] = useState(0)

    const btn_class = ["menu-btn-burger","menu-btn-closed"]
    const nav_class = ["nav_list_closed","nav_list_open"]

    return ( 
        <header className="header">

            <a href="#home" className="nav_logo">
                <img className="logo" src={Logo} alt="logo Chloé Garrigue"/>      
                <div className="header_name">
                        <p id="header_chloe">Chloé Garrigue</p>
                        <p>La Roche-sur-Yon</p>
                </div>                   
            </a>

            <nav>
                {/* pc version */}
                <span id="pc_menu">
                    <ul className="nav_list">
                        {menu.map((item => (
                            <li key={item.key}>
                                <a className="nav_link" href={item.link} id={item.id} key={item.key}>
                                {item.title}
                                </a>
                            </li>
                        )))}    
                    </ul>
                </span>

                {/* tablet and mobile version, dropdown menu*/}
                <span id="dp_menu">
                    {/* managing the hamburger menu button */}
                    <div className="menu-btn" onClick={() => open ? setOpen(0) : setOpen(1)}>
                        <div className={btn_class[open]}></div>
                    </div>
                    {/* managing the dropdown menu                 */}
                    <ul className={nav_class[open]}>
                        {menu.map((item => (
                            <li key={item.key}>
                                <a className="nav_link" href={item.link} id={item.id} onClick={() => setOpen(0)}>
                                {item.title}
                                </a>
                            </li>
                        )))}    
                    </ul>
                </span>
            </nav>            
            
        </header>

)}
 
export default Header;